import React from "react"
import { LandingLayout } from "@layout"
import { LandingHero } from "@components/hero"
import SEO from "@components/seo"
import {
  MainBlockTitleUnderline,
  LandingJoinColony,
  MainBlockTestimonials,
} from "@components/main-blocks"

const FB1 = () => (
  <LandingLayout
    backgroundImage="animated"
    hero={
      <LandingHero
        h1={
          <>
            Earn money
            <br /> with <span>ATM</span>.com
          </>
        }
        h2={
          <>
            Get paid for answering easy questions and playing games, no hard
            work required!
          </>
        }
        button="Get Started"
      />
    }
  >
    <SEO
      overrideTitleTemplate={`%s`}
      title="Earn Money with ATM.com - Real Cash Rewards Not Points"
      description="Earn money with the ATM.com® app! Claim your money today, download ATM app! Get paid for answering fun questions."
      image="/img/og/get-extra-cash-with-atm.png"
    />
    <MainBlockTitleUnderline className="white" />
    <MainBlockTestimonials />
    <LandingJoinColony />
  </LandingLayout>
)

export default FB1
